import React, { ReactNode } from 'react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { SocialsSection } from '../components/SocialsSection'
import '../styles/index.scss'

interface Props {
  children?: ReactNode
}

export const MainLayout = ({ children }: Props) => (
  <main>
    <Header />
    {children}
    <SocialsSection />
    <Footer />
  </main>
)
