import { Link } from 'gatsby'
import React from 'react'

type Props = {
  title: string
  salary: string
  location: string
  url: string
}

export const Position = ({ title, salary, location, url }: Props) => (
  <div className="positions-info-main">
    <div className="positions-title">
      <h6>{title}</h6>
    </div>
    <div className="positions-price">
      <p>{salary}</p>
    </div>
    <div className="positions-location">
      <p>{location}</p>
    </div>
    <div className="positions-link">
      <p>
        <svg
          width={24}
          height={24}
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="arrow-right-svg"
        >
          <path
            d="M16.3536 27.6036L15.6464 28.3107L16.3536 29.0178L17.0607 28.3107L16.3536 27.6036ZM28.6036 15.3536L29.3107 16.0607L29.3107 16.0607L28.6036 15.3536ZM15.6464 26.8964L14.9393 26.1893L14.2322 26.8964L14.9393 27.6036L15.6464 26.8964ZM27.5429 15L28.25 15.7071L28.9571 15L28.25 14.2929L27.5429 15ZM15.6464 3.10355L14.9393 2.39645L14.2322 3.10355L14.9393 3.81066L15.6464 3.10355ZM16.3536 2.39645L17.0607 1.68934L16.3536 0.982232L15.6464 1.68934L16.3536 2.39645ZM28.6036 14.6464L29.3107 13.9393L29.3107 13.9393L28.6036 14.6464ZM28.9571 15L29.6642 15.7071L30.3713 15L29.6642 14.2929L28.9571 15ZM1.44782 14.5V13.5H0.447819V14.5H1.44782ZM28.75 14.5H29.75V13.5H28.75V14.5ZM28.75 15.5V16.5H29.75V15.5H28.75ZM1.44782 15.5H0.447819V16.5H1.44782V15.5ZM17.0607 28.3107L29.3107 16.0607L27.8964 14.6464L15.6464 26.8964L17.0607 28.3107ZM14.9393 27.6036L15.6464 28.3107L17.0607 26.8964L16.3536 26.1893L14.9393 27.6036ZM26.8358 14.2929L14.9393 26.1893L16.3536 27.6036L28.25 15.7071L26.8358 14.2929ZM14.9393 3.81066L26.8358 15.7071L28.25 14.2929L16.3536 2.39645L14.9393 3.81066ZM15.6464 1.68934L14.9393 2.39645L16.3536 3.81066L17.0607 3.10355L15.6464 1.68934ZM29.3107 13.9393L17.0607 1.68934L15.6464 3.10355L27.8964 15.3536L29.3107 13.9393ZM29.6642 14.2929L29.3107 13.9393L27.8964 15.3536L28.25 15.7071L29.6642 14.2929ZM29.3107 16.0607L29.6642 15.7071L28.25 14.2929L27.8964 14.6464L29.3107 16.0607ZM1.94782 13.5H1.44782V15.5H1.94782V13.5ZM28.25 13.5H1.94782V15.5H28.25V13.5ZM28.75 13.5H28.25V15.5H28.75V13.5ZM29.75 15.5V14.5H27.75V15.5H29.75ZM28.25 16.5H28.75V14.5H28.25V16.5ZM1.94782 16.5H28.25V14.5H1.94782V16.5ZM1.44782 16.5H1.94782V14.5H1.44782V16.5ZM0.447819 14.5V15.5H2.44782V14.5H0.447819Z"
            fill="#10110D"
          />
        </svg>
      </p>
    </div>
    {/*link cover*/}
    <Link to={url} className="link-cover" aria-label="Open positions link" />
    {/*link cover*/}
  </div>
)
