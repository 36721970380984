import { graphql, Link } from 'gatsby'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { HeaderStatic } from '../components/Header'
import { Positions } from '../components/Positions'
import { SEO } from '../components/SEO'
import { MainLayout } from '../layouts/MainLayout'
import { Jobs } from '../utils/greenhouse'
import { isLocale } from '../utils/greenhouse/utils'

type Props = {
  data: {
    allGreenhouseJob: {
      nodes: Jobs
    }
  }
}

const HomePage = ({ data }: Props) => {
  const isUS = isLocale('us')

  return (
    <MainLayout>
      <SEO />
      <div className="main">
        <HeaderStatic />
        <div className="section-we-support">
          <div className="col-12 position-relative section-container">
            <div className="video-wrapper">
              {!isUS && (
                <ReactPlayer
                  url="https://cdn.fourthwall.com/jobs/video.mp4"
                  width="100%"
                  height="auto"
                  playing
                  muted
                  loop
                  playsinline
                />
              )}
            </div>
            <div className="background-filter" />
            <div className="content">
              <h2 className="section-title-large text-white">We support creators</h2>
              <p className="text-white">
                If you’re talented, hardworking and love helping others, we’d like to talk to you
                about joining our team.
              </p>
              <div className="readmore-btn-warp">
                <Link to="#positions" className="btn btn-primary scroll-click">
                  See open positions
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-what-we-do ha-waypoint"
          data-animate-down="ha-header-white"
          data-animate-up="ha-header-white"
        >
          <div className="what-wedo-warp">
            <div className="container">
              <div className="row desktop-view">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="section-panel section-panel-left">
                    <div className="section-top-media">
                      <figure
                        className="parallax"
                        data-rellax-speed={2}
                        data-rellax-percentage="0.5"
                      >
                        <img src="/images/what-we-do-media1.jpg" alt="" />
                      </figure>
                    </div>
                    <div
                      className="section-intro-main parallax"
                      data-rellax-speed={2}
                      data-rellax-percentage="0.2"
                    >
                      <div className="section-intro">
                        <p>
                          And yet these creators, especially when they are up-and-coming, often
                          don’t have the time to properly set up the money-making side of being a
                          creator.
                        </p>
                        <p>
                          That’s where we come in. Fourthwall is a platform that makes it easy for
                          creators to open their own, beautiful .com website where fans can directly
                          support them through buying products and donating.
                        </p>
                      </div>
                    </div>
                    <div className="section-bottom-media">
                      <figure
                        className="parallax"
                        data-rellax-speed="-0.5"
                        data-rellax-percentage="0.3"
                      >
                        <img src="/images/what-we-do-media2.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="section-panel section-panel-right">
                    <div className="section-intro-main-top">
                      <div className="section-intro">
                        <div className="section-header">
                          <h2 className="section-title">What we do</h2>
                        </div>
                        <p>
                          Platforms like YouTube, Instagram and TikTok have taken over
                          entertainment. Now anyone with something to share has the opportunity to
                          reach millions. For those who create something special, they can turn
                          their passion into a full-time job with hundreds of thousands or millions
                          of loyal fans that love what they do.
                        </p>
                      </div>
                    </div>
                    <div className="section-group-media">
                      <figure>
                        <img src="/images/what-we-do-media3.jpg" alt="" />
                      </figure>
                      <figure>
                        <img src="/images/what-we-do-media4.jpg" alt="" />
                      </figure>
                      <div className="section-intro">
                        <p>
                          And we do this while giving creators 100% ownership and control of their
                          website and brand. They own the branding, they own the domain, they own
                          the data.
                        </p>
                        <p>We give creators the freedom to focus on creating.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row visible-mobile">
                <div className="col-12">
                  <div className="section-intro-main-top">
                    <div className="section-intro">
                      <div className="section-header">
                        <h2 className="section-title">What we do</h2>
                      </div>
                      <p>
                        Platforms like YouTube, Instagram and TikTok have taken over entertainment.
                        Now anyone with something to share has the opportunity to reach millions.
                        For those who create something special, they can turn their passion into a
                        full-time job with hundreds of thousands or millions of loyal fans that love
                        what they do.
                      </p>
                      <figure>
                        <img src="/images/what-we-do-media1-mobile.jpg" alt="" />
                      </figure>
                      <p>
                        And yet these creators, especially when they are up-and-coming, often don’t
                        have the time to properly set up the money-making side of being a creator.
                      </p>
                      <p>
                        That’s where we come in. Fourthwall is a platform that makes it easy for
                        creators to open their own, beautiful .com website where fans can directly
                        support them through buying products and donating.
                      </p>
                      <div className="section-group-media">
                        <div className="scroll-div-media">
                          <figure>
                            <img src="/images/what-we-do-media3.jpg" alt="" />
                          </figure>
                          <figure>
                            <img src="/images/what-we-do-media4.jpg" alt="" />
                          </figure>
                        </div>
                      </div>
                      <p>
                        And we do this while giving creators 100% ownership and control of their
                        website and brand. They own the branding, they own the domain, they own the
                        data.
                      </p>
                      <p>We give creators the freedom to focus on creating.</p>
                      <figure className="last">
                        <img src="/images/what-we-do-media2-mobile.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section-who-we-are pad-y-t pad-y-b-80 ha-waypoint"
          data-animate-down="ha-header-black"
          data-animate-up="ha-header-white"
        >
          <div className="container">
            <div className="top-who-we-are pad-b-20">
              <div className="row">
                <div className="col-12">
                  <div className="section-header">
                    <h2 className="section-title">Who we are</h2>
                  </div>
                </div>
                <div className="col-12 col-md-9 col-lg-8">
                  <div className="who-we-are-media">
                    <figure>
                      <img src="/images/who-we-are-media.jpg" alt="" />
                    </figure>
                  </div>
                </div>
                {/* mobile design */}
                <div className="col-12 visible-mobile">
                  <div className="content-box content-top-box-intro light-gray box-pad-45 mar-b-20">
                    <div className="content-box-inner">
                      <p>
                        We're a sixty-strong team with offices in Los Angeles,
                        California and Poznan, Poland. Our culture is built around the philosophy of putting creators
                        first, and that if you love what you do, you'll never work a day in your
                        life.
                      </p>
                    </div>
                  </div>
                </div>
                {/* mobile design */}
                <div className="col-12 col-md-3 col-lg-4">
                  <div className="who-we-are-media">
                    <figure>
                      <img src="/images/who-we-are-media1.jpg" alt="" />
                      <span className="pic-shap-over">
                        <svg viewBox="0 0 242 200" xmlns="http://www.w3.org/2000/svg">
                          <g stroke="#0042FF" strokeWidth="2" fill="none" fillRule="evenodd">
                            <path d="M85.513 33.147C30.309 53.007 28.96 90.139 27.984 99.858c-5.349 53.255 22.918 101.261 90.337 99.07 31.104-1.011 89.343-7.941 113.343-73.929 14.72-40.47 14.968-80.684-39.735-89.985C121.71 23.074 58.513 48.043 1.521 84.616" />
                            <path d="M102 7c.515 5.107 2.485 11.893 3 17l-3-17z" />
                            <path d="M102 8.637c8.36-2.991 14.277 5.417 1.947 7.363" />
                            <path d="M103.983 16.054c14.882-.815 12.026 7.86-.983 9.946" />
                            <path d="M117.478 6.754s0 3.605 1.244 7.582 2.099 5.031 2.099 5.031" />
                            <path d="M123.535 5.82c0 12.384.21 12.22 10.43 8.027" />
                            <path d="M138.205 2.367l-.057 15.716" />
                            <path d="M136.712 5.176c4.564-5.347 13.056-5.504 1.464 5.473" />
                            <path d="M138.562 9.657c12.165-3.387 8.257 8.99-1.83 7.24" />
                            <path d="M150.317 4.378c.06 14.99 11.872 7.836 10.683 1.442-1.04-5.595-6.372-4.338-10.622-.252" />
                          </g>
                        </svg>
                      </span>
                    </figure>
                  </div>
                </div>
                {/* mobile design */}
                <div className="col-12 visible-mobile">
                  <div className="content-box founder-content light-gray box-pad-45 mar-b-20">
                    <div className="content-box-inner">
                      <h3 className="box-title">Founders</h3>
                      <ul>
                        <li>
                          <h6>Will Baumann</h6>
                          <p>Former CEO of SmartReceipts</p>
                        </li>
                        <li>
                          <h6>Walker Williams</h6>
                          <p>Former CEO of Teespring</p>
                        </li>
                        <li>
                          <h6>Eli Valentin</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* mobile design */}
              </div>
            </div>
            <div className="bottom-who-we-are">
              <div className="row">
                <div className="col-12 col-md-7 col-lg-7 desktop-view">
                  <div className="content-box content-top-box-intro light-gray box-pad-45 mar-b-20">
                    <div className="content-box-inner">
                      <p>
                        We're a thirty-strong team with offices in Poznan, Poland and Los Angeles,
                        California. Our culture is built around the philosophy of putting creators
                        first, and that if you love what you do, you'll never work a day in your
                        life.
                      </p>
                    </div>
                  </div>
                  <div className="content-box founder-content light-gray box-pad-45 mar-b-20">
                    <div className="content-box-inner">
                      <h3 className="box-title">Founders</h3>
                      <ul>
                        <li>
                          <h6>Will Baumann</h6>
                          <p>Former CEO of SmartReceipts</p>
                        </li>
                        <li>
                          <h6>Walker Williams</h6>
                          <p>Former CEO of Teespring</p>
                        </li>
                        <li>
                          <h6>Eli Valentin</h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 col-lg-5">
                  <div className="our-value-box primary-bg box-pad-45 mar-b-20">
                    <div className="our-value-box-inner">
                      <h3 className="box-title">Our values</h3>
                      <ul>
                        <li>
                          <h6>
                            We believe in working hard, but at a pace that we can sustain for years.
                          </h6>
                          <p>Building a great company is a marathon, not a sprint.</p>
                        </li>
                        <li>
                          <h6>We believe in personal greatness.</h6>
                          <p>
                            We love what we do and we're always learning, whether our focus is
                            engineering, support or ops.
                          </p>
                        </li>
                        <li>
                          <h6>We believe in honesty, but we don't tolerate assholes.</h6>
                          <p>
                            We're honest with one another, even when it’s difficult, but we have no
                            tolerance for personal attacks.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-our-backing pad-y-t pad-y-b">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5">
                <div className="our-backing-title-intro">
                  <h2 className="section-title">Our backing</h2>
                  <p>We’re backed with $17m from two of the world’s best investors.</p>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-7 desktop-view">
                <div className="our-backing-box first-box">
                  <div className="our-backing-box-inner">
                    <figure>
                      <img src="/images/our-backing-media1.jpg" alt="Alexis Ohanian" />
                    </figure>
                    <div className="backing-info">
                      <div className="backing-header">
                        <h5>Alexis Ohanian</h5>
                        <h6>
                          <img src="/images/logo-brand1.png" width={108} alt="Alexis Ohanian" />
                        </h6>
                      </div>
                      <p>
                        Alexis co-founded Reddit, where he still serves on the board, before
                        co-founding Initialized. Initialized has backed companies like Coinbase,
                        Flexport and Instacart.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-7 col-lg-7 desktop-view">
                <div className="our-backing-box force-top-align">
                  <div className="our-backing-box-inner">
                    <figure>
                      <img src="/images/our-backing-media2.jpg" alt="Jana Messerschmidt" />
                    </figure>
                    <div className="backing-info">
                      <div className="backing-header">
                        <h5>Jana Messerschmidt</h5>
                        <h6>
                          <img src="/images/logo-brand2.png" width={120} alt="Jana Messerschmidt" />
                        </h6>
                      </div>
                      <p>
                        Jana co-founded #ANGELS as well as served as VP of Global Business
                        Development at Twitter before joining Lightspeed. Lightspeed has backed
                        companies such as Bird, Affirm, Carta and Cameo.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*small view*/}
            <div className="visible-mobile">
              <div className="row">
                <div className="col-12">
                  <div className="our-backing-box-main">
                    <div className="our-backing-box-main-inner">
                      <div className="our-backing-box">
                        <div className="our-backing-box-inner">
                          <figure>
                            <img src="/images/our-backing-media1.jpg" alt="Alexis Ohanian" />
                          </figure>
                          <div className="backing-info">
                            <div className="backing-header">
                              <h5>Alexis Ohanian</h5>
                              <h6>
                                <img
                                  src="/images/logo-brand1.png"
                                  width={108}
                                  alt="Alexis Ohanian"
                                />
                              </h6>
                            </div>
                            <p>
                              Alexis co-founded Reddit, where he still serves on the board, before
                              co-founding Initialized. Initialized has backed companies like
                              Coinbase, Flexport and Instacart.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="our-backing-box">
                        <div className="our-backing-box-inner">
                          <figure>
                            <img src="/images/our-backing-media2.jpg" alt="Jana Messerschmidt" />
                          </figure>
                          <div className="backing-info">
                            <div className="backing-header">
                              <h5>Jana Messerschmidt</h5>
                              <h6>
                                <img
                                  src="/images/logo-brand2.png"
                                  width={123}
                                  alt="Jana Messerschmidt"
                                />
                              </h6>
                            </div>
                            <p>
                              Jana co-founded #ANGELS as well as served as VP of Global Business
                              Development at Twitter before joining Lightspeed. Lightspeed has
                              backed companies such as Bird, Affirm, Carta and Cameo.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*small view*/}
          </div>
        </div>
        <Positions />
      </div>
    </MainLayout>
  )
}

export default HomePage

export const query = graphql`
  {
    allGreenhouseJob {
      nodes {
        id
        title
        metadata {
          value {
            max_value
            min_value
            unit
          }
          value_type
          name
        }
        location {
          name
        }
      }
    }
  }
`
